<template>
  <v-rating
    v-model="value"
    name="note"
    active-color="#F6A90D"
    color="grey-lighten-1"
    empty-icon="mdi-star"
    length="5"
    size="25"
    class="mt-2 d-flex justify-center"
  />
</template>
<script setup lang="ts">
const props = defineProps<{
  name?: string;
}>();

const {value} = useField(() => props.name);
</script>
